<template>
    <div class="email w-1400">
        <div class="title">建议信箱</div>
        <div class="email-info">
            <p>征集建议信箱使用说明</p>
            <p>1. 为优化用户体验，我站特开通“征集建议信箱”，与广大网民、用户进行互动、沟通交流，希望能收到您宝贵的意见建议。</p>
            <p>2.为提高处理信件的效率，方便我们快速处理信件，请大家在信箱标题栏内根据内容简明扼要地注明信件主题内容，如实填写、表述清楚</p>
            <p>3.来信收到后我们将尽快处理，并进行回复。</p>
        </div>
        <div class="line"></div>
        <div class="formbox">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="标题" prop="title">
                    <el-input v-model.trim="ruleForm.title"></el-input>
                </el-form-item>
                <el-form-item label="内容" prop="suggestion">
                    <el-input type="textarea" :rows="5" v-model.trim="ruleForm.suggestion"></el-input>
                </el-form-item>
            </el-form>
            <div style="text-align:center">
                <el-button type="warning" v-if="token" @click="submit('ruleForm')" style="margin-left:20px">发布</el-button>
                <el-button type="warning" v-else @click="submit('ruleForm')" style="margin-left:10px">登录并发布</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { SuggestionAdd } from '@/api/other'
export default {
    computed:{
		...mapState({
			token:state=>state.token,
		})
	},
    watch:{
		token(a){
			if(a){
				this.submit('ruleForm');
			}
		}
	},
    data(){
        return{
            ruleForm:{suggestType:'开放性建议'},
            rules:{
                title:[{required: true, message: '请输入标题', trigger: 'blur' }],
                suggestion:[{required: true, message: '请输入内容', trigger: 'blur' }],
            }
        }
    },
    methods:{
        submit(formName){
            this.$refs[formName].validate((valid) => {
                if(valid){
                    if(this.token){
                        SuggestionAdd(this.ruleForm).then(res=>{
                            if(res.data.code === 1){
                                this.$message({
                                    message:'发布成功',
                                    type:'success'
                                })
                                this.ruleForm = {suggestType:'开放性建议'}
                            }else{
                                this.$message({
                                    message:res.msg,
                                    type:'error'
                                })
                            }
                        })
                    }else{
                        this.$bus.$emit('showLogin')
                    }
                }
            })
		},
    }
}
</script>

<style scoped>
.formbox{
    margin: 30px auto;
    width: 480px;
}
.title{
    font-size: 20px;
    font-weight: 600;
    color: #e4871c;
    line-height: 42px;
    border-bottom: 1px solid #e4871c;
}
.line{
    height: 1px;
    width: 1000px;
    margin:0 auto;
    background:  #e7e7e7;
}
.email-info{
    font-size: 20px;
    line-height: 40px;
    width: 800px;
    margin: 50px auto 20px;
}
.email-info p:first-child{
    text-align: center;
    font-size: 30px;
    margin-bottom: 16px;
}
</style>